import { Button, Chip, IconButton, List, ListItem, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import { getSubjectFile } from '../../../../../redux/subjectFileSlice';

function SubjectRightProperties(props) {

    const { nodeSelected, open, setOpenCreateSubjectConstraint, setNodes, setOpen } = props;
    const [label, setLabel] = useState("");
    const [description, setDescription] = React.useState("");
    const [subjectContraint, setSubjectContraint] = React.useState([]);
    const subjectFile = useSelector(getSubjectFile)

    const handleOpenCreateSubjectConstraint = () => {
        setOpenCreateSubjectConstraint(true);
    };

    const onChangeLabel = (value) => {
        setLabel(value)
        setNodes((nds) => nds.map((node) => {
            if (node.id === nodeSelected.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            label: value
                        }
                    },
                };
            }
            return node;
        }));
    };

    const onChangeDescription = (value) => {
        setDescription(value)
        setNodes((nds) => nds.map((node) => {
            if (node.id === nodeSelected.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            description: value
                        }
                    }
                };
            }
            return node;
        }));
    };

    useEffect(() => {
        if (nodeSelected !== undefined) {
            setLabel(nodeSelected.data.general_properties.label)
            setDescription(nodeSelected.data.general_properties.description)
            setSubjectContraint(nodeSelected.data.general_properties.subject_constraint)
        }
    }, [nodeSelected])

    useEffect(() => {
        if (subjectFile) {
            setOpen(false);
        }
    }, [subjectFile])

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '20px 15px',
            }}
        >
            {open ? (
                <>
                    <Typography>General Properties</Typography>
                    <TextField
                        required
                        id="node-label"
                        label="Label"
                        fullWidth
                        size='small'
                        margin="normal"
                        value={label}
                        onChange={(e) => onChangeLabel(e.target.value)}
                    />
                    <TextField
                        id="description"
                        label="Description"
                        fullWidth
                        size='small'
                        multiline
                        rows={3}
                        margin="normal"
                        value={description}
                        onChange={(e) => onChangeDescription(e.target.value)}
                    />
                    <Typography sx={{ marginTop: 2 }} variant='subtitle2'>Subject Constraint</Typography>
                    <div>
                        <List sx={{ padding: '0px' }}>
                            {subjectContraint.map((item, index) => {
                                return (
                                    <ListItem
                                        sx={{
                                            border: '1px solid #ccc',
                                            margin: '10px 0px',
                                            borderRadius: '5px'
                                        }}
                                        key={index}
                                        secondaryAction={
                                            <IconButton aria-label="more">
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                    >
                                        <Chip
                                            label={item}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                    <Button
                        sx={{
                            marginTop: 2,
                            border: '1px solid #ccc',
                        }}
                        variant="outlined"
                        fullWidth
                        onClick={() => handleOpenCreateSubjectConstraint()}
                    >
                        <AddIcon color='action' />
                    </Button>
                </>
            ) : (
                <>
                    <Typography>Subject Workspace</Typography>
                    <Typography mt={3} variant="caption" display="block" color="grey.500">Subject Name</Typography>
                    <Typography>{subjectFile?.subject_diagram_name}</Typography>
                    <Typography mt={3} variant="caption" display="block" color="grey.500">Description</Typography>
                    <Typography>{subjectFile?.description === '' ? '-' : subjectFile?.description}</Typography>
                </>
            )}
        </div >
    )
}

export default SubjectRightProperties